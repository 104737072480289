var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "查询", id: "search-card" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c("seniorSearch", {
                  attrs: {
                    filterTimeStr: "thisMonth",
                    getList: _vm.getList,
                    dropDownList: _vm.dropDownList,
                    isSearchInput: true,
                    isSearchInputTitle: "单据编号、仓库编号、名称",
                    dateTimeType: "daterange",
                  },
                  on: { isShowHighCom: _vm.getIsShowHigh },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showHigh,
                        expression: "showHigh",
                      },
                    ],
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "marT10 x-f" },
                      [
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("仓库"),
                        ]),
                        _c("SelectRemote", {
                          staticClass: "marR15",
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({
                              key: "listDecoration",
                              // option: {
                              //   option: {
                              //     buttons: [
                              //       {
                              //         type: 'more',
                              //         option: {
                              //           title: '选择仓库',
                              //           width: 1250,
                              //           type: 'TreeAndTable',
                              //           formData: $dialog({ key: 'store' }),
                              //         },
                              //       },
                              //     ],
                              //   },
                              // },
                            }).option,
                          },
                          model: {
                            value: _vm.queryParams.inStoreIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "inStoreIds", $$v)
                            },
                            expression: "queryParams.inStoreIds",
                          },
                        }),
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("单据状态"),
                        ]),
                        _c("SelectLocal", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: {
                              data: _vm.dict.type.bill_status,
                              labels: [{ title: "名称", label: "label" }],
                              value: "value",
                              label: "label",
                            },
                          },
                          model: {
                            value: _vm.queryParams.billStatuss,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "billStatuss", $$v)
                            },
                            expression: "queryParams.billStatuss",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        { staticClass: "orderInfo", attrs: { cardTitle: "单据信息" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c("operatingButton", {
                  attrs: {
                    getList: _vm.getList,
                    isAuditBtn: true,
                    multiple: _vm.multiple,
                  },
                  on: {
                    handleAdd: _vm.handleAdd,
                    handleDelete: function ($event) {
                      return _vm.auditBill("删除")
                    },
                    handleAudit: function ($event) {
                      return _vm.auditBill("审核")
                    },
                    handleRedoAudit: function ($event) {
                      return _vm.auditBill("反审核")
                    },
                  },
                }),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingTable,
                        expression: "loadingTable",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      "tooltip-effect": "dark",
                      border: "",
                      "max-height": _vm.tableHeight,
                      height: _vm.tableHeight,
                    },
                    on: {
                      "selection-change": _vm.handleSelectionChange,
                      "row-click": _vm.handleRowClick,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "50",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "序号",
                        align: "center",
                        prop: "userId",
                        width: "80",
                        type: "index",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.$index +
                                      1 +
                                      (_vm.queryParams.pageNum - 1) *
                                        _vm.queryParams.pageSize
                                  ) + " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "show-overflow-tooltip": "",
                        label: "单据编号",
                        align: "center",
                        prop: "billNo",
                        "min-width": "160",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "primary", target: "_blank" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(scope.row.billNo) + " ")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "单据状态",
                        align: "center",
                        prop: "billStatusName",
                        "min-width": "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "仓库编号",
                        align: "center",
                        prop: "inStoreNo",
                        "min-width": "160",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "仓库名称",
                        align: "center",
                        prop: "inStoreName",
                        "min-width": "160",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "单据金额",
                        align: "center",
                        prop: "billMoney",
                        "min-width": "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "审核人",
                        align: "center",
                        prop: "auditBy",
                        "min-width": "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "审核时间",
                        align: "center",
                        prop: "auditTime",
                        "min-width": "155",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "备注",
                        align: "center",
                        prop: "billRemark",
                        "min-width": "155",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "修改人",
                        align: "center",
                        prop: "updateBy",
                        "min-width": "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "修改时间",
                        align: "center",
                        prop: "updateTime",
                        "min-width": "155",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "创建人",
                        align: "center",
                        prop: "createBy",
                        "min-width": "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "创建时间",
                        align: "center",
                        prop: "createTime",
                        "min-width": "155",
                      },
                    }),
                  ],
                  1
                ),
                _c("bottomPage", {
                  attrs: {
                    getList: _vm.getList,
                    idsCom: _vm.ids,
                    totalCom: _vm.total,
                  },
                  on: { selectAllCom: _vm.selectAll },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }